<template>
  <div class="header">
    <div class="title-nav">
      <div class="nav-left">
        <div class="logo">
          <img src="../assets/images/logo1.png" alt="" />
        </div>
      </div>
      <ul class="nav-right">
        <li @click="$router.push('/')">
          首页
          <div class="border-bottom"></div>
        </li>
        <!-- <li>产品介绍<i class="iconfont iconxiala"></i><div class="border-bottom"></div></li> -->
        <li @click="$router.push('/about')">关于我们<div class="border-bottom"></div></li>
        <!-- <li @click="$router.push('/application')">合作申请<div class="border-bottom"></div></li> -->
      </ul>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.title-nav {
  width: 100%;
  height: 191px;
  background: linear-gradient(125deg, #4762e2 0%, #2e25cf 100%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  .nav-left {
    .logo {
      width: 250px;
      height: 111px;
      margin-top: 52px;
      img {
        width: 250px;
        /* height: 111px; */
      }
    }
  }
  .nav-right {
    display: flex;
    min-width: 200px;
    margin-left: 334px;
    li {
      min-width: 96px;
      height: 50px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 50px;
      margin-right: 92px;
      cursor: pointer;
      .border-bottom {
        position: relative;
        left: 50%;
        top: 0;
        width: 48px;
        height: 2px;
        transform: translateX(-50%);
        border-bottom: 2px solid #4CF6E0FF;
        display: none;
      }
    }
    li:nth-of-type(1) {
      min-width: 48px;
    }
    li:nth-of-type(4) {
      margin-right: 0;
    }
    li:hover {
      color: #43f4dd;
      .border-bottom{
        display: block;
      }
    }
  }
}
.iconxiala:before {
  position: absolute;
  font-size: 24px;
  text-indent: 0.2em;
}
</style>