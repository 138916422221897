<template>
  <div class="about">
    <Header></Header>
    <div class="about-banner">
      <img src="../../assets/images/topimg.png" alt="" />
    </div>
    <div class="about-recommend">
      <div class="recommend-title">
        <h6>ABOUT</h6>
        <i>·</i>
        <h2>关于我们</h2>
      </div>
      <div class="recommend-text">
        <div class="name">
          <h3>杭州车富引擎科技有限公司</h3>
          <h4></h4>
        </div>
        <div class="about-pic">
          <img src="../../assets/images/About.png" alt="" />
        </div>
        <div class="company">
          卡车侠是一家创新型商用车后市场产业AI电商公司，致力于通过科技创新优化商用车后市场产业供应链，通过数字化赋能商用车后市场产业供给改革。<br /><br />
          通过智能供应链开启商用车养车产业新零售变革，围绕国家汽车后市场战略打造一个开放、共享、高效、智能的商用车后市场产业AI电商平台。
          <br /><br />
          卡车侠依托移动互联网、大数据、物联网、人工智能等新兴技术手段，重构了商用车后市场行业的信息流、资金流和物流。<br /><br />
          于打通商用车服务上下游全产业链，打造商用车服务电子商务服务平台，建立全国的商用车服务产业生态圈，致力于成为卡车司机用户发现并获得优价好物的首选平台。同时,卡车侠电商积极引入优质合作伙伴,为商家变现提供多元的选择。团购、秒杀、拼团,满足商家达人多形式变现需求。
        </div>
        <ul>
          <li>
            <img src="../../assets/images/one.png" alt="" />
            <h5>使命</h5>
            <i>通过卡车侠软件，在现实生活中将全国数干万卡车司机联系起来，让他们在任何地方都能有归属感</i>
          </li>
          <li>
            <img src="../../assets/images/two.png" alt="" />
            <h5>愿景</h5>
            <i>成为卡车司机最值得信赖的平台服务商</i>
          </li>
          <li>
            <img src="../../assets/images/three.png" alt="" />
            <h5>价值观</h5>
            <i>追求极致 务实敢为<br>开放谦虚 坦诚清晰<br>始终创业</i>
          </li>
        </ul>
      </div>
    </div>
    <div class="about-contact">
      <div class="contact-pic">
        <img src="../../assets/images/CONTACT US.png" alt="" />
      </div>
      <div class="contact-title">
        <h6>CONTACT</h6>
        <i>·</i>
        <h2>联系我们</h2>
      </div>

      <div class="content">
        <div class="content-left">
          <div class="left-pic">
            <img src="../../assets/images/map small.png" alt="" />
          </div>
          <ul class="left-message">
            <li>电话：400 999 8590</li>
            <li>邮箱：KCX@kachexia.cn</li>
            <li>地址：浙江省杭州市拱墅区候圣街99号顺丰创新中心</li>
          </ul>
        </div>
        <!-- <ul class="content-right">
          <li>
            <i>联系姓名</i>
            <el-input v-model="name" placeholder="请输入您的姓名"></el-input>
          </li>
          <li>
            <i>联系电话</i>
            <el-input
              v-model="call"
              placeholder="请输入您的联系电话"
            ></el-input>
          </li>
          <li>
            <i>留言</i>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入您的留言"
              v-model="textarea"
            >
            </el-input>
          </li>
          <li>
            <i>提交信息</i>
            <img src="../../assets/images/right.png" alt="" />
          </li>
        </ul> -->
      </div>
    </div>
    <div class="about-Cooperation">
      <div class="Cooperation-title">
        <h6>Cooperation</h6>
        <i>·</i>
        <h2>合作商户</h2>
      </div>
            <img src="../../assets/images/a2.png" alt="" />
            <img src="../../assets/images/a3.png" alt="" />
      <!-- <div class="Cooperation-one">
        <div class="pic2">
          <img src="../../assets/images/2.png" alt="" />
        </div>
        <div class="pic3">
          <img src="../../assets/images/3.png" alt="" />
        </div>
        <div class="pic1">
          <img src="../../assets/images/1.png" alt="" />
        </div>
        <div class="pic4">
          <img src="../../assets/images/4.png" alt="" />
        </div>
      </div>
      <div class="Cooperation-two">
        <div class="pic5">
          <img src="../../assets/images/5.png" alt="" />
        </div>
        <div class="pic7">
          <img src="../../assets/images/7.png" alt="" />
        </div>
        <div class="pic6">
          <img src="../../assets/images/6.png" alt="" />
        </div>
      </div>
      <div class="Cooperation-three">
        <div class="pic8">
          <img src="../../assets/images/8.png" alt="" />
        </div>
        <div class="pic9">
          <img src="../../assets/images/9.png" alt="" />
        </div>
        <div class="pic10">
          <img src="../../assets/images/10.png" alt="" />
        </div>
      </div>
      <div class="Cooperation-text">
        全国有1000+网点覆盖，主要分布东北地区、陕西甘肃，江浙沪及广州等地区；
        50%分布在物流节点城市，50%分布在高速加油点，服务网点全部为主营油站和大型
        民营加油站，车用尿素质量有保证，可溯源。
        目前有合作物流运力行业等多家知名企业。
      </div> -->
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
export default {
  data() {
    return {
      name: "",
      call: "",
      textarea: "",
    };
  },
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
.about {
  overflow: hidden;
  .about-banner {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
    img {
      width: 1266px;
      height: 684px;
    }
  }
  .about-recommend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 139px;
    .recommend-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 413px;
      height: 38px;
      h6 {
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 38px;
      }
      i {
        font-style: normal;
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2e25cfff;
        line-height: 38px;
      }
      h2 {
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2e25cfff;
        line-height: 38px;
      }
    }
    .recommend-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 111px;
      position: relative;
      .name {
        margin-right: 500px;
        h3 {
          width: 450px;
          height: 38px;
          font-size: 30px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 38px;
        }
        h4 {
          width: 115px;
          height: 1px;
          background: #2e25cfff;
          margin: 33px 0;
        }
      }

      .about-pic {
        /* position: relative; */
        img {
          width: 387px;
          /* height: 169px; */
          position: absolute;
          left: -81px;
          top: -65px;
        }
      }
      .company {
        width: 1033px;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 38px;
        margin-bottom: 108px;
      }
      ul {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 130px;
          img {
            width: 144px;
            height: 144px;
          }
          h5 {
            height: 38px;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 38px;
            margin: 30px 0 16px;
          }
          i {
            display: block;
            width: 300px;
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 31px;
            font-style: normal;
          }
        }
        li:nth-of-type(2) {
          i {
            width: 300px;
            height: 125px;
          }
        }
        li:nth-of-type(3) {
          margin-right: 0;
          i {
            width: 260px;
            height: 130px;
            text-align: center;
          }
        }
      }
    }
  }
  .about-contact {
    padding-bottom: 40px;
    background: linear-gradient(139deg, #4762e2 0%, #2e25cf 100%);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 162px;
    .contact-pic {
      position: relative;
      margin-right: 262px;
      img {
        width: 396px;
        height: 38px;
        position: absolute;
        left: 50%;
        top: 110px;
        transform: translateX(-50%);
      }
    }
    .contact-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 481px;
      height: 38px;
      margin: 93px 0 83px;
      h6 {
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 38px;
      }
      i {
        font-style: normal;
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 38px;
      }
      h2 {
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 38px;
      }
    }
    .content {
      display: flex;
      .content-left {
        /* height: 100%; */
        margin-right: 116px;
        display: flex;
        .left-pic {
          margin-bottom: 35px;
          img {
            width: 355px;
            height: 205px;
          }
          margin-right: 100px;
        }
        .left-message {
          li {
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 50px;
          }
          li:nth-of-type(3) {
            width: 370px;
          }
        }
      }
      .content-right {
        /* width: 100%; */
        li {
          display: flex;
          align-items: center;
          margin-bottom: 37px;
          i {
            font-style: normal;
            width: 120px;
            height: 38px;
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 38px;
            margin-right: 31px;
          }
        }
        li:nth-of-type(3) {
          align-items: flex-start;
        }
        li:nth-of-type(4) {
          width: 157px;
          height: 43px;
          background: #ffffff;
          border-radius: 22px;
          justify-content: space-evenly;
          align-items: center;
          margin-left: 100%;
          transform: translateX(-115%);
          i {
            width: 72px;
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2e25cf;
            line-height: 25px;
          }
          img {
            width: 13px;
            height: 13px;
          }
        }
      }
    }
  }
  .about-Cooperation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .Cooperation-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 523px;
      margin-bottom: 20px;
      h6 {
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 38px;
      }
      i {
        font-style: normal;
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2e25cfff;
        line-height: 38px;
      }
      h2 {
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2e25cfff;
        line-height: 38px;
      }
    }
    /* h2 {
      width: 207px;
      height: 84px;
      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 84px;
      letter-spacing: 1px;
    }
    h4 {
      width: 186px;
      height: 8px;
      background: #2b1ecd;
      border-radius: 4px;
      margin-top: 18px;
      margin-bottom: 106px;
    } */
    .Cooperation-one {
      display: flex;
      align-items: center;
      margin-top: 72px;
      .pic1 {
        img {
          width: 93px;
          height: 92px;
          margin: 0 103px 0 84px;
        }
      }
      .pic2 {
        img {
          width: 280px;
          height: 111px;
          margin-right: 18px;
        }
      }
      .pic3 {
        img {
          width: 279px;
          height: 126px;
          /* margin-right: 54px; */
        }
      }
      .pic4 {
        img {
          width: 102px;
          height: 103px;
        }
      }
    }
    .Cooperation-two {
      display: flex;
      align-items: center;
      .pic5 {
        img {
          width: 249px;
          height: 80px;
        }
      }
      .pic6 {
        img {
          width: 375px;
          height: 98px;
        }
      }
      .pic7 {
        img {
          width: 299px;
          height: 98px;
          margin: 0 23px 0 29px;
        }
      }
    }
    .Cooperation-three {
      display: flex;
      align-items: center;
      .pic8 {
        img {
          width: 278px;
          height: 135px;
        }
      }
      .pic9 {
        img {
          width: 230px;
          height: 102px;
          margin: 0 86px 0 47px;
        }
      }
      .pic10 {
        img {
          width: 144px;
          height: 103px;
          margin-right: 231px;
        }
      }
    }
    .Cooperation-text {
      width: 1175px;
      height: 205px;
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 50px;
      letter-spacing: 1px;
      margin-top: 77px;
      margin-bottom: 163px;
    }
  }
}
/deep/ .el-input__inner {
  background: none;
  width: 392px;
  height: 56px;
  border-radius: 6px;
  border: 1px solid #ffffff;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
/deep/ .el-input__inner::placeholder {
  width: 140px;
  height: 38px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 38px;
}
/deep/ .el-textarea__inner {
  background: none;
  width: 392px;
  height: 163px;
  border-radius: 6px;
  border: 1px solid #ffffff;
  resize: none;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
/deep/ .el-textarea__inner::placeholder {
  width: 140px;
  height: 38px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 38px;
}
/deep/ .title-nav .nav-right li:nth-of-type(3) {
  color: #43f4dd;
  .border-bottom {
    display: block;
  }
}
/* /deep/ .title-nav {
  padding: 0 364px;
  box-sizing: border-box;
} */
</style>