<template>
  <div class="application">
    <Header></Header>
    <div class="application-cooperrative">
      <div class="cooperrative-title">
        <h6>COOPERRATIVE</h6>
        <i>·</i>
        <h2>合作申请</h2>
      </div>
      <div class="cooperrative-pic">
        <img src="../../assets/images/illustrator.png" alt="" />
      </div>
      <ul class="cooperrative-msg">
        <li>
          <el-select v-model="city" placeholder="请选择城市">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <el-input v-model="addr" placeholder="请输入详细地址"></el-input>
        </li>
        <li>
          <el-input v-model="contact" placeholder="请输入联系人"></el-input>
        </li>
        <li>
          <el-input v-model="call" placeholder="请输入联系电话"></el-input>
        </li>
        <li>
          <el-select v-model="cooperation" placeholder="请选择合作项">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请填写其他需求，最多200字"
            v-model="msg"
          >
          </el-input>
        </li>
      </ul>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
export default {
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      city: "",
      addr: "",
      contact: "",
      call: "",
      cooperation: "",
      msg: "",
    };
  },
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
.application {
  .application-cooperrative {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .cooperrative-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 617px;
      height: 38px;
      margin: 162px 0 104px;
      h6 {
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 38px;
      }
      i {
        font-style: normal;
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2e25cfff;
        line-height: 38px;
      }
      h2 {
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2e25cfff;
        line-height: 38px;
      }
    }
    .cooperrative-pic {
      margin-bottom: 126px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 703px;
        height: 585px;
      }
    }
    .cooperrative-msg {
      margin-bottom: 184px;
      li {
        margin-bottom: 16px;
      }
    }
  }
}
/deep/ .el-input__inner {
  width: 688px;
  height: 62px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-size: 20px;
} 
/deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: none!important;
}
/deep/ .el-input__inner::placeholder {
  width: 100px;
  height: 31px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 31px;
}
/deep/ .el-textarea__inner {
  width: 688px;
  height: 173px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  resize: none;
  font-size: 20px;
}
/deep/ .el-textarea__inner::placeholder {
  width: 256px;
  height: 31px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 31px;
}
/deep/ .title-nav .nav-right li:nth-of-type(4) {
  color: #43f4dd;
  .border-bottom {
    display: block;
  }
}
/* /deep/ .title-nav {
  padding: 0 364px;
  box-sizing: border-box;
} */
</style>