<template>
  <div class="home">
    <div class="home-title">
      <Header></Header>
      <div class="title-doneBox">
        <div class="title-done">- 我们要做的</div>
        <div class="box"></div>
      </div>
      <div class="title-serveBox">
        <div class="title-serve">成为卡车司机最值得信赖的平台服务商！</div>
        <div class="box"></div>
      </div>
      <div class="title-detailsBox">
        <div class="title-details">
          致力于成为卡车司机用户发现并获得优价好物的首选平台。同时,卡车侠电商积极引入优质合作伙伴,为商家变现提供多元的选择。团购、秒杀、拼团,满足商家达人多形式变现需求。
        </div>
        <div class="box"></div>
      </div>

      <!-- <div class="title-btnBox">
        <img src="../../assets/images/more.png" alt="" />
        <div class="box"></div>
      </div> -->
      <div class="title-pic">
        <img src="../../assets/images/illustration.png" alt="" />
      </div>
    </div>
    <div class="home-culture">
      <div class="culture-title">
        <h6>CULTURE</h6>
        <i>·</i>
        <h2>企业文化</h2>
      </div>
      <!-- <h4></h4> -->
      <ul>
        <li>
          <img src="../../assets/images/mission.png" alt="" />
          <h5>使命</h5>
          <i>通过卡车侠软件，在现实生活中将全国数干万卡车司机联系起来，让他们在任何地方都能有归属感</i>
        </li>
        <li>
          <img src="../../assets/images/vision.png" alt="" />
          <img src="../../assets/images/vision2.png" alt="" />
          <h5>愿景</h5>
          <i>成为卡车司机最值得信赖的平台服务商</i>
        </li>
        <li>
          <img src="../../assets/images/values2.png" alt="" />
          <img src="../../assets/images/values.png" alt="" />
          <h5>价值观</h5>
          <i style="line-height:1.5">追求极致 务实敢为<br>开放谦虚 坦诚清晰<br>始终创业</i>
        </li>
      </ul>
    </div>
    <div class="home-website">
      <div class="website-title">
        <h6>NETWORK POINT</h6>
        <i>·</i>
        <h2>网点规模</h2>
      </div>
      <!-- <h4></h4> -->
      <img src="../../assets/images/website.png" alt="" />
      <ul class="partner-num">
        <li>
          <h3>1000+</h3>
          <h5>商家</h5>
        </li>
        <li>
          <h3>80+</h3>
          <h5>企业</h5>
        </li>
        <li>
          <h3>100000+</h3>
          <h5>司机</h5>
        </li>
      </ul>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Header from "../../components/Header";
import Footer from "../../components/Footer";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
.home {
  /* min-width: 1920px; */
  height: 100%;
  background: #ffffff;
  overflow: hidden;
  min-width: 1200px;
  margin: 0 auto;
  .home-title {
    height: 1100px;
    background: url("../../assets/images/image-banner.png") no-repeat;
    background-size: 100% 80%;
    .title-doneBox {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .title-done {
        min-width: 152px;
        height: 50px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 50px;
        margin-top: 50px;
      }
      .box {
        min-width: 619px;
        margin-left: 440px;
      }
    }
    .title-serveBox {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .title-serve {
        width: 592px;
        min-width: 592px;
        height: 120px;
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 60px;
        margin: 31px 0 50px 0;
      }
      .box {
        min-width: 619px;
      }
    }
    .title-detailsBox {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .title-details {
        padding-top: 60px;
        width: 553px;
        min-width: 553px;
        height: 99px;
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 1.5;
      }
      .box {
        min-width: 619px;
        margin-left: 39px;
      }
    }
    .title-btnBox {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 240.11px;
        margin-top: 60px;
      }
      .box {
        min-width: 619px;
        margin-left: 352px;
      }
    }
    .title-pic {
      position: relative;
      /* min-width: 804px; */
      img {
        width: 804px;
        height: 382px;
        position: absolute;
        /* left: 46%; */
        left: 50%;
        top: -287px;
      }
    }
  }
  .home-culture {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    .culture-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 473px;
      height: 38px;
      h6 {
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 38px;
      }
      i {
        font-style: normal;
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2e25cfff;
        line-height: 38px;
      }
      h2 {
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2e25cfff;
        line-height: 38px;
      }
    }
    /* h4 {
      width: 186px;
      height: 8px;
      background: #2b1ecd;
      border-radius: 4px;
      margin-top: 18px;
    } */
    ul {
      display: flex;
      justify-content: center;
      /* justify-content: space-evenly; */
      padding: 0 329px;
      box-sizing: border-box;
      li {
        width: 360px;
        height: 394px;
        background: #ffffff;
        box-shadow: 5px 10px 30px 0px rgba(41, 26, 204, 0.12);
        border-radius: 6px;
        padding: 50px 82px 113px 78px;
        box-sizing: border-box;
        margin: 294px 0 369px 64px;
        img {
          width: 81px;
          height: 82px;
        }
        h5 {
          /* width: 47px; */
          height: 29px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4a4a4a;
          line-height: 29px;
          margin: 36px 0 22px;
        }
        i {
          width: 223px;
          height: 22px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9b9b9b;
          line-height: 22px;
          font-style: normal;
        }
      }
      li:nth-of-type(1) {
        margin-left: 0;
      }
      li:nth-of-type(2),
      li:nth-of-type(3) {
        img:nth-of-type(2) {
          display: none;
        }
      }
      li:nth-of-type(2):hover,
      li:nth-of-type(3):hover {
        img:nth-of-type(1) {
          display: none;
        }
        img:nth-of-type(2) {
          display: block;
        }
      }
      li:hover {
        width: 410px;
        height: 449px;
        background: #2b1ecd;
        box-shadow: 5px 10px 30px 0px rgba(41, 26, 204, 0.12);
        border-radius: 6px;
        padding: 107px 94px 102px 93px;
        box-sizing: border-box;
        margin-top: 262px;
        margin-bottom: 346px;
        h5 {
          color: #ffffff;
        }
        i {
          color: #ffffff;
        }
      }
    }
  }
  .home-website {
    /* width: 77%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .website-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 650px;
      height: 38px;
      h6 {
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 38px;
      }
      i {
        font-style: normal;
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2e25cfff;
        line-height: 38px;
      }
      h2 {
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2e25cfff;
        line-height: 38px;
      }
    }
    /* h2 {
      width: 207px;
      height: 84px;
      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 84px;
      letter-spacing: 1px;
    }
    h4 {
      width: 186px;
      height: 8px;
      background: #2b1ecd;
      border-radius: 4px;
      margin-top: 18px;
    } */
    img {
      width: 1473px;
      height: 1066px;
      margin-top: 85px;
      /* margin-bottom: 238px; */
    }
    .partner-num {
      margin: 156px 0 203px;
      display: flex;
      justify-content: center;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 243px;
        h3 {
          font-size: 70px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          background: linear-gradient(to bottom, #4762e2ff, #2611c9ff);
          -webkit-background-clip: text;
          color: transparent;
        }
        h5 {
          font-size: 40px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2e25cf;
        }
      }
      li:nth-of-type(3) {
        margin-right: 0;
      }
    }
  }
  .home-partner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .partner-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 523px;
      height: 38px;
      h6 {
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 38px;
      }
      i {
        font-style: normal;
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2e25cfff;
        line-height: 38px;
      }
      h2 {
        font-size: 48px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2e25cfff;
        line-height: 38px;
      }
    }
    /* h2 {
      width: 207px;
      height: 84px;
      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 84px;
      letter-spacing: 1px;
    }
    h4 {
      width: 186px;
      height: 8px;
      background: #2b1ecd;
      border-radius: 4px;
      margin-top: 18px;
      margin-bottom: 106px;
    } */
    .partner-pic {
      display: flex;
      margin-top: 106px;
      .pic1 {
        img {
          width: 150px;
          height: 150px;
          margin-right: 79px;
        }
      }
      .pic2 {
        img {
          width: 390px;
          height: 156px;
          margin-right: 35px;
        }
      }
      .pic3 {
        img {
          width: 392px;
          height: 177px;
          margin-right: 54px;
        }
      }
      .pic4 {
        img {
          width: 168px;
          height: 170px;
        }
      }
    }
    .partner-picture {
      display: flex;
      .pic5 {
        img {
          width: 334px;
          height: 108px;
          margin-right: 46px;
        }
      }
      .pic6 {
        img {
          width: 490px;
          height: 128px;
          margin-right: 46px;
        }
      }
      .pic7 {
        img {
          width: 378px;
          height: 124px;
        }
      }
    }
    .partner-text {
      width: 1267px;
      height: 223px;
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 50px;
      letter-spacing: 1px;
      margin-top: 77px;
    }
    .partner-num {
      margin: 156px 0 203px;
      display: flex;
      justify-content: center;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 243px;
        h3 {
          font-size: 70px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          background: linear-gradient(to bottom, #4762e2ff, #2611c9ff);
          -webkit-background-clip: text;
          color: transparent;
        }
        h5 {
          font-size: 40px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2e25cf;
        }
      }
      li:nth-of-type(3) {
        margin-right: 0;
      }
    }
  }
}
.iconxiala:before {
  position: absolute;
  font-size: 24px;
  text-indent: 0.2em;
}
/deep/ .title-nav .nav-right li:nth-of-type(1) {
  color: #43f4dd;
  .border-bottom {
    display: block;
  }
}
/deep/ .title-nav {
  background: none;
}
</style>
