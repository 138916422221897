<template>
  <div class="home-footer">
    <div class="footer-msg">
      <div class="footer-logo">
        <div class="logo">
          <img src="../assets/images/logo2.png" alt="" />
        </div>
      </div>
      <div class="footer-line"></div>
      <ul class="footer-nav">
        <li>首页</li>
        <li>产品</li>
        <li>关于我们</li>
      </ul>
      <ul class="footer-details">
        <li>联系我们</li>
        <li>
          <img src="../assets/images/icon-phone.png" alt="" />
          400 999 8590
        </li>
        <li>
          <img src="../assets/images/icon-email.png" alt="" />
          KCX@kachexia.cn
        </li>
      </ul>
    </div>

    <div class="footer-qrcode">
      <img src="../assets/images/qrcode.png" alt="" />
      <i>扫码了解更多</i>
    </div>

    <div class="icp">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备2020033295号</a>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.home-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 313px;
  background: #f7f5fe;
  position: relative;

  .footer-msg {
    display: flex;
    align-items: center;
    .footer-logo {
      .logo {
        img {
          width: 203px;
          margin-top: -40%;
        }
      }
    }
    .footer-line {
      width: 1px;
      height: 112px;
      background: #2b1ecdff;
      margin-left: 58px;
    }
    .footer-nav {
      margin: 0 59px 0 75px;
      li {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2b1ecd;
        line-height: 33px;
        margin-bottom: 22px;
        min-width: 100px;
      }
      li:nth-of-type(3) {
        margin-bottom: 0;
      }
    }
    .footer-details {
      li {
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2b1ecd;
        line-height: 30px;
        margin-bottom: 27px;
        min-width: 200px;
        display: flex;
        align-items: center;
        img {
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
      }
      li:nth-of-type(2) {
        display: flex;
      }
      li:nth-of-type(3) {
        display: flex;
      }
      li:nth-of-type(3) {
        margin-bottom: 0;
      }
    }
  }

  .footer-qrcode {
    display: flex;
    align-items: center;
    margin-left: 220px;
    img {
      width: 157px;
      height: 157px;
    }
    i {
      display: block;
      font-style: normal;
      width: 59px;
      height: 90px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      margin-left: 32px;
    }
  }
  .icp {
    font-size: 14px;
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    text-align: center;
    a {
      color: #999 !important;
    }
  }
}
</style>