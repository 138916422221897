var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"home-title"},[_c('Header'),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3)],1),_vm._m(4),_vm._m(5),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-doneBox"},[_c('div',{staticClass:"title-done"},[_vm._v("- 我们要做的")]),_c('div',{staticClass:"box"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-serveBox"},[_c('div',{staticClass:"title-serve"},[_vm._v("成为卡车司机最值得信赖的平台服务商！")]),_c('div',{staticClass:"box"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-detailsBox"},[_c('div',{staticClass:"title-details"},[_vm._v(" 致力于成为卡车司机用户发现并获得优价好物的首选平台。同时,卡车侠电商积极引入优质合作伙伴,为商家变现提供多元的选择。团购、秒杀、拼团,满足商家达人多形式变现需求。 ")]),_c('div',{staticClass:"box"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-pic"},[_c('img',{attrs:{"src":require("../../assets/images/illustration.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-culture"},[_c('div',{staticClass:"culture-title"},[_c('h6',[_vm._v("CULTURE")]),_c('i',[_vm._v("·")]),_c('h2',[_vm._v("企业文化")])]),_c('ul',[_c('li',[_c('img',{attrs:{"src":require("../../assets/images/mission.png"),"alt":""}}),_c('h5',[_vm._v("使命")]),_c('i',[_vm._v("通过卡车侠软件，在现实生活中将全国数干万卡车司机联系起来，让他们在任何地方都能有归属感")])]),_c('li',[_c('img',{attrs:{"src":require("../../assets/images/vision.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../assets/images/vision2.png"),"alt":""}}),_c('h5',[_vm._v("愿景")]),_c('i',[_vm._v("成为卡车司机最值得信赖的平台服务商")])]),_c('li',[_c('img',{attrs:{"src":require("../../assets/images/values2.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../assets/images/values.png"),"alt":""}}),_c('h5',[_vm._v("价值观")]),_c('i',{staticStyle:{"line-height":"1.5"}},[_vm._v("追求极致 务实敢为"),_c('br'),_vm._v("开放谦虚 坦诚清晰"),_c('br'),_vm._v("始终创业")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-website"},[_c('div',{staticClass:"website-title"},[_c('h6',[_vm._v("NETWORK POINT")]),_c('i',[_vm._v("·")]),_c('h2',[_vm._v("网点规模")])]),_c('img',{attrs:{"src":require("../../assets/images/website.png"),"alt":""}}),_c('ul',{staticClass:"partner-num"},[_c('li',[_c('h3',[_vm._v("1000+")]),_c('h5',[_vm._v("商家")])]),_c('li',[_c('h3',[_vm._v("80+")]),_c('h5',[_vm._v("企业")])]),_c('li',[_c('h3',[_vm._v("100000+")]),_c('h5',[_vm._v("司机")])])])])
}]

export { render, staticRenderFns }